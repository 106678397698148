<template>
  <div class="text-body-1 px-5">
    <v-row class="my-0">
      <v-col class="scrollable col-general overflow-auto py-0" cols="4">
        <general></general>
      </v-col>

      <v-col class="scrollable col-main py-0">
        <main-detail></main-detail>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import General from "./components/General";
import MainDetail from "./components/MainDetail";

export default {
  components: {
    General,
    MainDetail
  },

  created() {
    const route = this.$route;
    if (
      route.name.includes("not-sold-warehouse_internal-guarantee-notes-create")
    ) {
      if (route.query && route.query.serial_number) {
        const {
          serial_number,
          branch_id,
          serial_id,
          assigned_to_id,
          supervisor_id
        } = route.query;
        //setSerialInfo
        this.$store.dispatch("INTERNAL_GUARANTEE_NOTE/setOffBizDetailLocal", {
          branch_id: Number(branch_id),
          serial_id: Number(serial_id),
          assigned_to_id: Number(assigned_to_id),
          supervisor_id: Number(supervisor_id)
        });
        this.$store.dispatch(
          "INTERNAL_GUARANTEE_NOTE/getSerialInfoBySerialNumber",
          {
            serial_number,
            store_name_param: route.query.store_name
          }
        );
      }
    }

    if (route.name === "not-sold-warehouse_internal-guarantee-notes-detail") {
      this.$store.dispatch(
        "INTERNAL_GUARANTEE_NOTE/getOffBizById",
        route.params.offBizId
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.scrollable {
  max-height: calc(100vh - 64px - 53px - 24px);
  @media only screen and (max-width: 960px) {
    max-height: calc(100vh - 56px - 53px - 24px);
  }
}
.col-general {
  max-width: 320px;
}
.col-main {
  flex: 1;
}
</style>
