<template>
  <div style="height: 100%">
    <!-- Start: Top -->
    <div class="d-flex flex-row align-center">
      <div class="text-h5 font-weight-bold">Xử lý bảo hành nội bộ</div>
      <v-chip
        v-if="
          offBizDetail.selected_action === selectedActions.approval_for_sale
        "
        class="white font-weight-bold ml-3"
        color="green"
        outlined
      >
        Đã duyệt bán
      </v-chip>
      <v-spacer></v-spacer>
      <v-btn
        v-if="offBizDetail.id && !hasCancelOrSold()"
        class="red lighten-4 red--text text-accent-2 rounded-lg"
        depressed
        :loading="statusRequest.value === 'loading-addOffBiz'"
        @click="
          statusRequest.value === 'loading-addOffBiz'
            ? null
            : openModalCancelingOffBiz()
        "
      >
        Hủy
      </v-btn>
      <!--      <v-btn-->
      <!--        v-if="offBizDetail.id && !hasCancelOrSold()"-->
      <!--        class="rounded-lg"-->
      <!--        color="primary"-->
      <!--        depressed-->
      <!--        :loading="false"-->
      <!--        @click="updateOffBiz(selectedActions.save)"-->
      <!--      >-->
      <!--        Lưu-->
      <!--      </v-btn>-->
      <v-btn
        v-if="!offBizDetail.id"
        class="rounded-lg"
        color="primary"
        depressed
        :loading="false"
        @click="createOffBiz"
      >
        Thêm
      </v-btn>
      <v-btn
        class="primary rounded-lg ml-3"
        dark
        v-if="
          offBizDetail.id &&
            offBizDetail.step === stepList.length &&
            !hasCancelOrSold()
        "
        depressed
        :loading="false"
        @click="updateOffBiz(selectedActions.approval_for_sale)"
      >
        Duyệt bán
      </v-btn>
    </div>
    <!-- End: Top -->
    <!-- Start: Cancel reason -->
    <v-card
      v-if="offBizDetail.selected_action === selectedActions.cancel"
      class="mt-5"
      flat
    >
      <div class="amber lighten-5 text-body-1 px-3 py-2">
        <span class="font-weight-bold">Lý do hủy:</span>
        {{ offBizDetail.ly_do_huy }}
      </div>
    </v-card>
    <!-- End: Cancel reason -->
    <!-- Start: Main content -->
    <div class="main-detail--content mt-5">
      <card-processing
        class="card-processing mt-5"
        :step-list="stepList"
        :store-name="'INTERNAL_GUARANTEE_NOTE'"
      ></card-processing>
    </div>
    <!-- End: Main content -->
    <off-biz-cancel-modal
      v-if="offBizDetail.id"
      :homeRoute="'not-sold-warehouse_internal-guarantee-notes'"
      :storeName="'INTERNAL_GUARANTEE_NOTE'"
      :type="'bảo hành'"
      :code="offBizDetail.id.toString()"
      :id="offBizDetail.id"
      :item="offBizDetail"
    ></off-biz-cancel-modal>
  </div>
</template>

<script>
import CardProcessing from "@/modules/NotSoldWarehouse/components/CardProcessing";
import OffBizCancelModal from "../../../../components/modal/off-biz-cancel-modal.vue";
import { SELECTED_ACTIONS } from "../../../../constants/constants";
export default {
  components: {
    CardProcessing,
    OffBizCancelModal
  },
  data() {
    return {
      stepList: [
        { id: 1, name: "Đã tiếp nhận" },
        { id: 2, name: "Đã gửi đi" },
        { id: 3, name: "Đã về, chờ kiểm tra" },
        { id: 4, name: "Đã kiểm tra xong" }
      ],
      selectedActions: SELECTED_ACTIONS
    };
  },
  computed: {
    offBizDetail() {
      return this.$store.getters["INTERNAL_GUARANTEE_NOTE/offBizDetail"];
    },
    statusRequest() {
      return this.$store.getters["INTERNAL_GUARANTEE_NOTE/statusRequest"];
    },
    ortherPageStatusRequest() {
      const route = this.$route;
      const { store_name } = route.query;
      return this.$store.getters[`${store_name}/statusRequest`];
    }
  },

  beforeDestroy() {
    this.$store.dispatch("INTERNAL_GUARANTEE_NOTE/resetOffBizDetail");
  },
  methods: {
    openModalCancelingOffBiz() {
      this.$modal.show({
        name: "cancel-offbiz-modal",
        payload: {
          item: this.offBizDetail
        }
      });
    },

    hasCancelOrSold() {
      const { selected_action } = this.offBizDetail;
      if (
        selected_action === SELECTED_ACTIONS.cancel ||
        selected_action === SELECTED_ACTIONS.approval_for_sale
      ) {
        return true;
      }
      return false;
    },

    async createOffBiz() {
      // Request create
      await this.$store.dispatch(
        "INTERNAL_GUARANTEE_NOTE/addOffBiz",
        this.offBizDetail
      );
      // Alert and replace router
      if (this.statusRequest.value === "success-addOffBiz") {
        // Alert
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm phiếu bảo hành thành công"
          }
        });
        // handle next invoice
        const route = this.$route;
        const {
          branch_id,
          serial_id,
          assigned_to_id,
          step,
          store_name,
          offbiz_id,
          go_back_route
        } = route.query;
        if (serial_id === this.offBizDetail.serial_id) {
          await this.$store.dispatch(`${store_name}/updateOffBiz`, {
            branch_id,
            serial_id,
            assigned_to_id,
            step,
            nextStep: step,
            selected_action: SELECTED_ACTIONS.approval_for_sale,
            id: offbiz_id,
            next_invoice: {
              model_id: this.offBizDetail.id
            }
          });

          if (this.ortherPageStatusRequest.value === "success-updateOffBiz") {
            return this.$router.push({
              name: go_back_route,
              params: {
                offBizId: offbiz_id
              }
            });
          }
          return;
        }
        // Replace router
        await this.$router.push({
          name: "not-sold-warehouse_internal-guarantee-notes"
        });
      }
    },

    async updateOffBiz(selected_action) {
      // Request create
      const { step } = this.offBizDetail;
      if (
        (selected_action === SELECTED_ACTIONS.tranfer ||
          selected_action === SELECTED_ACTIONS.approval_for_sale) &&
        step < this.stepList.length
      ) {
        return;
      }
      await this.$store.dispatch("INTERNAL_GUARANTEE_NOTE/updateOffBiz", {
        ...this.offBizDetail,
        selected_action
      });
      // Alert
      if (this.statusRequest.value === "success-updateOffBiz") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu cập nhật"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main-detail--content {
  max-height: calc(100% - 36px - 20px);
  overflow-y: auto;
}
</style>
